import { useCallback, useEffect, useState, useRef } from 'react';
import './App.css';
import Image from './Image';
import Timer from './Timer';
import Logo from './assets/hostopia_whitep-min.png';
import Logo2x from './assets/hostopia_whitep@2x-min.png';
import ReactPlayer from 'react-player';
import useSound from 'use-sound';
import gamesound from './assets/background.mp3';
import baseImages from './images';
import Bird from './assets/bird.png';
import Bird2x from './assets/bird@2x.png';

const makeImageArray = () => {
  return [...baseImages, ...baseImages].sort(() => 0.5 - Math.random());
};

const INITIAL_TIME = 50;

function App() {
  const [imageArray, setImageArray] = useState(makeImageArray());
  const [play, { duration, stop }] = useSound(gamesound, {
    interrupt: true,
    loop: true,
  });
  const [couple, setCouple] = useState([]);
  const [indexCouple, setIndexCouple] = useState([]);
  const [flippedArray, setFlippedArray] = useState([]);
  const [isPlaying, setPlaying] = useState(false);
  const [isStarted, setStarted] = useState(false);
  const [canRestart, setRestart] = useState(false);
  const timerRef = useRef();

  useEffect(() => {
    play();

    return () => {
      stop();
    };
  }, [play, stop, duration]);

  useEffect(() => {
    if (couple.length === 2) {
      if (couple[0] === couple[1]) {
        setFlippedArray((state) => [...state, ...indexCouple]);
        setTimeout(() => {
          setCouple([]);
          setIndexCouple([]);
        }, 300);
      } else {
        setTimeout(() => {
          setCouple([]);
          setIndexCouple([]);
        }, 300);
      }
    }
  }, [couple, indexCouple]);

  useEffect(() => {
    if (flippedArray.length === baseImages.length * 2) {
      stop();
      setPlaying(true);
      timerRef.current.timerPause();
    }
  }, [flippedArray.length, stop]);

  const clickHandler = useCallback(
    (image, index) => {
      if (couple.length === 2) return;
      if (flippedArray.includes(index)) return;
      if (indexCouple.includes(index)) return;

      if (!couple[0]) {
        setIndexCouple([index]);
        setCouple([image.name]);
        return;
      }
      if (!couple[1]) {
        setIndexCouple([...indexCouple, index]);
        setCouple([...couple, image.name]);
        return;
      }
    },
    [couple, flippedArray, indexCouple]
  );

  const startHandler = useCallback(
    (time = INITIAL_TIME * 1000) => {
      setStarted(true);
      timerRef.current.timerStart(time);
      if (canRestart) {
        setImageArray(makeImageArray());
        setCouple([]);
        setIndexCouple([]);
        setFlippedArray([]);
        setRestart(false);
      }
    },
    [canRestart]
  );

  const endCallback = useCallback(() => {
    setStarted(false);
    if (flippedArray.length !== baseImages.length * 2) {
      setRestart(true);
    }
  }, [flippedArray.length]);

  return (
    <div className='container'>
      <header className='header'>
        <div className='logo-wrapper'>
          <div className='logo'>
            <img src={Logo} srcSet={`${Logo2x} 2x`} alt='' />
          </div>
        </div>
        <div className='text-wrapper'>
          <h2>
            {canRestart
              ? 'Sorry, your time ran out.'
              : flippedArray.length === baseImages.length * 2
              ? 'Congratulations! you did it!'
              : 'Solve our Match Game'}
          </h2>
          <p>
            {canRestart ? (
              <>
                Please try again
                <br />
                <br />
              </>
            ) : flippedArray.length === baseImages.length * 2 ? (
              <>
                <br />
                <br />
              </>
            ) : (
              <>
                in {INITIAL_TIME} seconds or less to reveal
                <br /> a present from The Snowman.
              </>
            )}
          </p>
        </div>
      </header>
      <div className='image-container'>
        <div className='holder' />
        <div className={`video ${isPlaying ? '' : 'video-hidden'}`}>
          <ReactPlayer
            playing={isPlaying}
            width='100%'
            height='100%'
            playsinline
            url='https://player.vimeo.com/video/649266944?h=a48e1df2a6'
          />
        </div>
        <div className={`bird ${isPlaying ? 'bird-fadeout' : 'bird-hidden'}`}>
          <img src={Bird} srcSet={`${Bird2x} 2x`} alt='' />
        </div>
        <div className='images'>
          {imageArray.map((image, index) => (
            <Image
              key={index}
              index={index}
              image={image}
              onClick={clickHandler}
              isFlipped={
                indexCouple.includes(index) || flippedArray.includes(index)
              }
              isOpened={flippedArray.includes(index)}
              isStarted={isStarted}
            />
          ))}
        </div>
      </div>
      <footer className='footer'>
        <Timer
          ref={timerRef}
          isStarted={isStarted}
          endCallback={endCallback}
          initialTime={INITIAL_TIME * 1000}
        />
        {!isStarted && (
          <button className='control-button' onClick={startHandler}>
            {canRestart ? 'Restart' : 'Start'}
          </button>
        )}
      </footer>
    </div>
  );
}

export default App;
