import './App.css';
import ReactCardFlip from 'react-card-flip';
import front from './assets/front.png';
import frontRetina from './assets/front@2x.png';

const Front = () => (
  <img src={front} srcSet={`${frontRetina} 2x`} alt='' className='front' />
);

const Back = ({ image }) => (
  <div className='back'>
    <img src={image.file} srcSet={`${image.retinaFile} 2x`} alt='' />
  </div>
);

function Image({ index, image, onClick, isFlipped, isOpened, isStarted }) {
  return (
    <div
      className={`image ${isOpened ? 'is-opened' : ''} ${
        isStarted ? '' : 'blocked'
      }`}
      onClick={() => {
        if (onClick && isStarted) onClick(image, index);
      }}>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipSpeedBackToFront={0.3}
        flipSpeedFrontToBack={0.3}
        containerClassName='react-card-flip-container'>
        <Front />
        <Back image={image} />
      </ReactCardFlip>
    </div>
  );
}

export default Image;
