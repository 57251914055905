import img1 from './assets/game/image-1.jpg';
import img2 from './assets/game/image-2.jpg';
import img3 from './assets/game/image-3.jpg';
import img4 from './assets/game/image-4.jpg';
import img5 from './assets/game/image-5.jpg';
import img6 from './assets/game/image-6.jpg';
import img7 from './assets/game/image-7.jpg';
import img8 from './assets/game/image-8.jpg';
import img9 from './assets/game/image-9.jpg';

import img1x2 from './assets/game/image-1@2x.jpg';
import img2x2 from './assets/game/image-2@2x.jpg';
import img3x2 from './assets/game/image-3@2x.jpg';
import img4x2 from './assets/game/image-4@2x.jpg';
import img5x2 from './assets/game/image-5@2x.jpg';
import img6x2 from './assets/game/image-6@2x.jpg';
import img7x2 from './assets/game/image-7@2x.jpg';
import img8x2 from './assets/game/image-8@2x.jpg';
import img9x2 from './assets/game/image-9@2x.jpg';

const baseImages = [
  { name: 'image-1', file: img1, retinaFile: img1x2 },
  { name: 'image-2', file: img2, retinaFile: img2x2 },
  { name: 'image-3', file: img3, retinaFile: img3x2 },
  { name: 'image-4', file: img4, retinaFile: img4x2 },
  { name: 'image-5', file: img5, retinaFile: img5x2 },
  { name: 'image-6', file: img6, retinaFile: img6x2 },
  { name: 'image-7', file: img7, retinaFile: img7x2 },
  { name: 'image-8', file: img8, retinaFile: img8x2 },
  { name: 'image-9', file: img9, retinaFile: img9x2 },
];
export default baseImages;
